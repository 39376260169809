@charset "UTF-8";
#header {
  width: 80%;
  height: 80px;
  position: fixed;
  top: 20px;
  z-index: 20;
  background: white;
  left: 50%;
  transform: translate(-50%, 0%);
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  min-width: 1200px; }
  @media screen and (max-width: 1200px) {
    #header {
      left: 0px;
      transform: translate(0%, 0%); } }
  #header #header-container {
    height: 100%;
    margin: 0 35px;
    text-align: center;
    position: relative; }
    #header #header-container img {
      position: absolute;
      top: 50%;
      left: 0px;
      transform: translate(0%, -50%); }
    #header #header-container #header-menu {
      list-style: none;
      margin: 0px;
      height: 100%;
      display: inline-block;
      font-weight: 500;
      font-size: 17px;
      padding: 0px; }
      #header #header-container #header-menu li {
        cursor: pointer;
        padding: 0px 20px;
        float: left;
        height: 100%;
        line-height: 80px;
        position: relative; }
        #header #header-container #header-menu li #header-expand-container {
          position: absolute;
          height: 0px;
          overflow: hidden;
          top: 100%;
          left: 0px;
          font-size: 12px;
          text-align: center;
          background: #ffffff;
          box-shadow: 0 5px 5px rgba(57, 63, 72, 0.3);
          line-height: 50px;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease; }
          #header #header-container #header-menu li #header-expand-container span {
            display: inline-block;
            line-height: 20px;
            white-space: nowrap;
            color: black; }
      #header #header-container #header-menu .header-expand-li-active {
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        color: white;
        background-color: #f42434; }
      #header #header-container #header-menu .header-expand-show {
        height: 150px !important; }
    #header #header-container #header-language {
      height: 100%;
      margin: 0px;
      padding: 0px;
      display: inline-block;
      list-style: none;
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translate(0%, -50%);
      font-family: 'TitilliumWeb';
      font-weight: 500; }
      #header #header-container #header-language li {
        height: 100%;
        float: left;
        line-height: 80px;
        font-size: 14px;
        position: relative;
        cursor: pointer; }
      #header #header-container #header-language li:first-child::after {
        content: "";
        width: 1px;
        height: 10px;
        display: inline-block;
        background: black;
        margin: 0px 10px; }
    #header #header-container #header-logo {
      width: 60px;
      vertical-align: bottom; }

.active-scroll {
  background-color: white;
  -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  width: 100% !important;
  top: 0px !important;
  height: 60px !important; }
  .active-scroll #header-container > ul > li {
    line-height: 60px !important; }

.disappear-scroll {
  top: -60px !important; }

#main {
  width: 100%;
  min-height: 1077px;
  position: relative; }
  #main #main-logo-image {
    width: 160px;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%); }
  #main #main-logo-text {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 60%;
    font-size: 30px;
    transform: translate(-50%, -50%);
    font-weight: 300; }

@keyframes move_wave {
  0% {
    transform: translateX(0) translateZ(0) scaleY(1); }
  50% {
    transform: translateX(-25%) translateZ(0) scaleY(0.55); }
  100% {
    transform: translateX(-50%) translateZ(0) scaleY(1); } }
  #main #wave .waveWrapper {
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto; }
  #main #wave .waveWrapperInner {
    position: absolute;
    width: 100%;
    overflow: hidden;
    height: 100%;
    bottom: -1px; }
  #main #wave .bgTop {
    z-index: 15;
    opacity: 0.5; }
  #main #wave .bgMiddle {
    z-index: 10;
    opacity: 0.75; }
  #main #wave .bgBottom {
    z-index: 5; }
  #main #wave .wave {
    position: absolute;
    left: 0;
    width: 200%;
    height: 100%;
    background-repeat: repeat no-repeat;
    background-position: 0 bottom;
    transform-origin: center bottom; }
  #main #wave .waveTop {
    background-size: 50% 100px; }
  #main #wave .waveAnimation .waveTop {
    animation: move-wave 3s;
    -webkit-animation: move-wave 3s;
    -webkit-animation-delay: 1s;
    animation-delay: 1s; }
  #main #wave .waveMiddle {
    background-size: 50% 120px; }
  #main #wave .waveAnimation .waveMiddle {
    animation: move_wave 10s linear infinite; }
  #main #wave .waveBottom {
    background-size: 50% 100px; }
  #main #wave .waveAnimation .waveBottom {
    animation: move_wave 15s linear infinite; }

#do {
  padding-top: 100px; }
  #do .do-menu-container {
    position: relative;
    height: 120px; }
    #do .do-menu-container .do-menu-item-container {
      display: inline-block;
      height: 170px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
    #do .do-menu-container .do-menu-item {
      display: inline-block;
      margin: 0px 50px;
      cursor: pointer;
      text-align: center;
      vertical-align: middle;
      width: 80px;
      height: 80px;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }
      #do .do-menu-container .do-menu-item img {
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease; }
  #do .do-bullet-line {
    width: 370px;
    height: 3px;
    background-color: #b3b3b3;
    margin: 0 auto;
    position: relative; }
    #do .do-bullet-line .do-bullet {
      position: absolute;
      width: 10px;
      height: 10px;
      left: 0%;
      top: 1.25px;
      transform: translate(-50%, -50%);
      background: #b3b3b3;
      border-radius: 50%;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }
    #do .do-bullet-line .do-bullet-active {
      background: black; }
    #do .do-bullet-line .do-bullet-active-line {
      position: absolute;
      width: 0%;
      height: 3px;
      background: black;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }
    #do .do-bullet-line .do-bullet-active-line:after {
      content: "";
      position: absolute;
      top: 0%;
      right: -14.5px;
      width: 13px;
      height: 13px;
      transform: translate(-50%, -50%);
      background-color: white;
      border-radius: 50%;
      border: 3px solid black; }
  #do .swiper-container {
    padding: 100px 0px; }
  #do .do-content-container {
    width: 1px;
    height: 600px;
    margin: 0 auto;
    position: relative; }
    #do .do-content-container .do-content-item {
      position: absolute; }
    #do .do-content-container #do-content-web-text {
      left: -470px;
      top: 370px; }
    #do .do-content-container #do-content-web-browser {
      left: -300px; }
      #do .do-content-container #do-content-web-browser img {
        width: 600px; }
    #do .do-content-container #do-content-web-photoshop {
      left: 100px;
      top: 140px; }
    #do .do-content-container #do-content-web-coding {
      left: 10px;
      top: 327px; }
    #do .do-content-container #do-content-responsive-iphone {
      left: -530px;
      bottom: 20px; }
    #do .do-content-container #do-content-responsive-ipad {
      left: -500px; }
    #do .do-content-container #video-tablet {
      position: absolute;
      z-index: -1;
      width: 327px;
      left: 260px;
      top: 35px; }
    #do .do-content-container #video-smartphone {
      position: absolute;
      z-index: -1;
      width: 128px;
      left: 170px;
      top: 35px; }
    #do .do-content-container #video-default {
      position: absolute;
      z-index: -1;
      left: 297px;
      width: 604px;
      top: 34px; }
    #do .do-content-container #do-content-responsive-macbook {
      left: -250px;
      bottom: 10px; }
    #do .do-content-container #do-content-responsive-text {
      left: -550px;
      top: 70px; }
    #do .do-content-container #do-content-outsourcing-background {
      left: -200px;
      top: 62.5px; }
    #do .do-content-container #do-content-outsourcing-people {
      left: 35px;
      top: 0px; }
    #do .do-content-container #do-content-outsourcing-text {
      left: -550px;
      top: 70px; }
    #do .do-content-container #do-content-outsourcing-smartphone {
      left: -48px;
      top: 45px; }
    #do .do-content-container #do-content-outsourcing-bulb {
      left: -176px;
      top: 130px; }
    #do .do-content-container #do-content-outsourcing-mail {
      left: -210px;
      top: 280px; }
    #do .do-content-container #do-content-outsourcing-wheel-small {
      left: -60px;
      top: 410px; }
      #do .do-content-container #do-content-outsourcing-wheel-small img {
        -webkit-animation: spin-reverse 4s linear infinite;
        -moz-animation: spin-reverse 4s linear infinite;
        animation: spin-reverse 4s linear infinite; }
    #do .do-content-container #do-content-outsourcing-wheel-big {
      left: -150px;
      top: 450px; }
      #do .do-content-container #do-content-outsourcing-wheel-big img {
        -webkit-animation: spin 4s linear infinite;
        -moz-animation: spin 4s linear infinite;
        animation: spin 4s linear infinite; }
    #do .do-content-container #do-content-outsourcing-pointer {
      left: 170px;
      top: 390px; }
    #do .do-content-container #do-content-outsourcing-chat1 {
      left: 315px;
      top: 300px; }
      #do .do-content-container #do-content-outsourcing-chat1 img {
        animation: floating 3s ease-in-out infinite; }
    #do .do-content-container #do-content-outsourcing-chat2 {
      left: 360px;
      top: 360px; }
      #do .do-content-container #do-content-outsourcing-chat2 img {
        animation: floating 3s ease-in-out infinite; }
    #do .do-content-container #do-content-outsourcing-money {
      left: 355px;
      top: 180px; }
    #do .do-content-container #do-content-outsourcing-briefcase {
      left: 275px;
      top: 15px; }
  #do .do-menu-active {
    opacity: 1; }
    #do .do-menu-active img {
      width: 60px;
      margin-bottom: 10px; }
    #do .do-menu-active p {
      margin: 0px;
      font-size: 16px;
      font-weight: 700; }
  #do .do-menu-inactive {
    opacity: 0.45; }
    #do .do-menu-inactive img {
      width: 50px;
      margin-bottom: 10px; }
    #do .do-menu-inactive p {
      margin: 0px;
      font-size: 15px;
      font-weight: 700; }
  #do .video-image-container {
    width: 100%;
    height: 100%;
    position: relative; }

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg); } }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes spin-reverse {
  100% {
    -moz-transform: rotate(-360deg); } }

@-webkit-keyframes spin-reverse {
  100% {
    -webkit-transform: rotate(-360deg); } }

@keyframes spin-reverse {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

@keyframes floating {
  from {
    transform: translate(0, 0px); }
  65% {
    transform: translate(0, 10px); }
  to {
    transform: translate(0, 0px); } }

@-webkit-keyframes floating {
  from {
    -webkit-transform: translate(0, 0px); }
  65% {
    -webkit-transform: translate(0, 10px); }
  to {
    -webkit-transform: translate(0, 0px); } }

#history #history-container {
  width: auto;
  padding: 0px 14vw; }
  #history #history-container .history-year-container {
    width: 100%;
    padding: 30px 0px;
    position: relative; }
    #history #history-container .history-year-container .history-year {
      width: 15%;
      display: inline-block;
      float: left; }
      #history #history-container .history-year-container .history-year p {
        font-family: "TitilliumWeb";
        font-weight: 600;
        color: #f42434;
        font-size: 24px;
        margin: 0px;
        letter-spacing: -0.095em; }
    #history #history-container .history-year-container .history-month-content-container {
      width: 85%;
      display: inline-block; }
      #history #history-container .history-year-container .history-month-content-container p {
        font-size: 16px;
        margin: 0px; }
      #history #history-container .history-year-container .history-month-content-container .history-month-content-line {
        margin-bottom: 20px; }
        #history #history-container .history-year-container .history-month-content-container .history-month-content-line .history-month-container {
          width: 4%;
          display: inline-block;
          float: left; }
          #history #history-container .history-year-container .history-month-content-container .history-month-content-line .history-month-container p {
            font-weight: 600; }
        #history #history-container .history-year-container .history-month-content-container .history-month-content-line .history-content-container {
          width: 96%;
          display: inline-block; }
          #history #history-container .history-year-container .history-month-content-container .history-month-content-line .history-content-container p {
            font-weight: 400; }
      #history #history-container .history-year-container .history-month-content-container .history-month-content-line:last-child {
        margin-bottom: 0px; }
  #history #history-container .history-year-container::after {
    content: "";
    width: 100%;
    height: 1px;
    background: #dcdcdc;
    position: absolute;
    bottom: 0px;
    left: 0px; }

#partner #partner-container {
  padding: 40px 4vw;
  display: grid;
  grid-template-columns: auto auto auto auto auto; }

#partner .partner-card {
  width: 15vw;
  height: 10vw;
  min-width: 200px;
  min-height: 133.33px;
  border-radius: 20px;
  display: inline-block;
  position: relative;
  margin: 0px 0.5vw;
  margin-bottom: 20px;
  text-align: center;
  -moz-box-shadow: 2px 4px 10px 3px #ccc;
  -webkit-box-shadow: 2px 4px 10px 3px #ccc;
  box-shadow: 2px 4px 10px 3px #ccc;
  margin: 30px auto; }
  #partner .partner-card img {
    width: 70%;
    height: 70%;
    object-fit: scale-down;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
  #partner .partner-card .partner-information-container {
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    #partner .partner-card .partner-information-container .partner-information {
      width: 100%;
      height: 100%;
      position: absolute;
      background: black;
      opacity: 0.8;
      border-radius: 20px; }
    #partner .partner-card .partner-information-container .partner-information-text {
      color: white;
      margin: 0px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 20px;
      pointer-events: none; }
  #partner .partner-card .partner-information-container-show {
    opacity: 1 !important; }

#member #member-container {
  display: grid;
  padding: 40px 4vw;
  grid-template-columns: auto auto auto auto auto auto; }
  #member #member-container .member-people-container {
    padding: 20px 0px;
    width: 90%;
    border-radius: 20px;
    background: white;
    -moz-box-shadow: 2px 4px 10px 3px #ccc;
    -webkit-box-shadow: 2px 4px 10px 3px #ccc;
    box-shadow: 2px 4px 10px 3px #ccc;
    margin: 20px auto;
    text-align: center; }
    #member #member-container .member-people-container .member-people-picture {
      width: 8vw;
      height: 8vw;
      margin: 20px auto;
      position: relative;
      border-radius: 50%;
      box-shadow: 0 13px 26px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.2);
      background-size: cover;
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      filter: grayscale(100%); }
    #member #member-container .member-people-container .co-founder-ko::after {
      content: '공동설립자';
      position: absolute;
      background: black;
      color: white;
      padding: 0.3vw 0.5vw;
      border-radius: 10px;
      right: -2vw;
      top: 0px;
      font-size: 12px; }
    #member #member-container .member-people-container .co-founder-en::after {
      content: 'Co-Founder';
      position: absolute;
      background: black;
      color: white;
      padding: 0.3vw 0.5vw;
      border-radius: 10px;
      right: -2vw;
      top: 0px;
      font-size: 12px; }
    #member #member-container .member-people-container p {
      margin: 0px; }
    #member #member-container .member-people-container .member-people-name {
      font-size: 17px;
      text-align: center;
      font-weight: 500;
      margin: 0px; }
    #member #member-container .member-people-container .member-people-position {
      font-size: 15px;
      text-align: center;
      color: #565656;
      display: inline-block;
      z-index: 0;
      position: relative; }
    #member #member-container .member-people-container .member-people-position::before {
      content: '';
      width: 0%;
      height: 35%;
      position: absolute;
      left: -10%;
      top: 60%;
      background: #ffd969;
      z-index: -1;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }
    #member #member-container .member-people-container .member-people-position-effect::before {
      content: '';
      width: 120%;
      height: 35%;
      position: absolute;
      left: -10%;
      top: 60%;
      background: #ffd969;
      z-index: -1;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }
    #member #member-container .member-people-container .member-people-role {
      font-size: 14px;
      text-align: center;
      color: #565656;
      display: inline-block;
      z-index: 0;
      position: relative; }
    #member #member-container .member-people-container .member-people-role::before {
      content: '';
      width: 0%;
      height: 35%;
      position: absolute;
      left: -10%;
      top: 60%;
      background: #ffd969;
      z-index: -1;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }
    #member #member-container .member-people-container .member-people-role-effect::before {
      content: '';
      width: 120%;
      height: 35%;
      position: absolute;
      left: -10%;
      top: 60%;
      background: #ffd969;
      z-index: -1;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }

#notice_press #npContainer {
  display: grid;
  padding: 40px 4vw;
  grid-template-columns: 50% 50%; }

#notice_press .npWrapper {
  width: 90%;
  margin: 0 auto;
  height: 300px; }
  #notice_press .npWrapper .np-title-container {
    position: relative;
    margin-bottom: 30px; }
    #notice_press .npWrapper .np-title-container .np-title {
      display: inline-block;
      font-size: 20px;
      font-weight: 600;
      margin: 0px; }
    #notice_press .npWrapper .np-title-container .np-viewall {
      position: absolute;
      right: 0px;
      bottom: 10px;
      font-size: 14px;
      background: black;
      color: white;
      border-radius: 20px;
      padding: 2px 10px; }
  #notice_press .npWrapper table {
    width: 100%;
    margin-top: 20px; }
    #notice_press .npWrapper table td {
      font-size: 15px;
      padding-bottom: 10px;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
      #notice_press .npWrapper table td a {
        text-decoration: none;
        color: black; }
    #notice_press .npWrapper table td:nth-child(2) {
      text-align: right;
      font-size: 15px;
      color: #565656; }

#notice_press .np-divider {
  width: 10%;
  height: 1px;
  background: black; }

#contact #contact-container {
  width: 70%;
  height: 35vw;
  margin: 0 auto;
  background: white;
  -moz-box-shadow: 2px 4px 10px 3px #ccc;
  -webkit-box-shadow: 2px 4px 10px 3px #ccc;
  box-shadow: 2px 4px 10px 3px #ccc;
  position: relative;
  overflow: hidden;
  min-height: 420px; }
  #contact #contact-container #contact-inside-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5vw; }
    @media screen and (max-width: 1200px) {
      #contact #contact-container #contact-inside-container {
        padding: 60px; } }
    #contact #contact-container #contact-inside-container p {
      margin: 0px; }
    #contact #contact-container #contact-inside-container #contact-name-container {
      padding: inherit;
      position: absolute;
      left: 0px;
      top: 0px; }
      #contact #contact-container #contact-inside-container #contact-name-container #contact-name-en {
        font-family: 'Montserrat';
        font-size: 3vw; }
        @media screen and (max-width: 1200px) {
          #contact #contact-container #contact-inside-container #contact-name-container #contact-name-en {
            font-size: 36px; } }
      #contact #contact-container #contact-inside-container #contact-name-container #contact-name-ko {
        margin-top: -5px;
        font-size: 1.5vw;
        font-weight: 600;
        color: #939598; }
        @media screen and (max-width: 1200px) {
          #contact #contact-container #contact-inside-container #contact-name-container #contact-name-ko {
            font-size: 18px; } }
        #contact #contact-container #contact-inside-container #contact-name-container #contact-name-ko span {
          font-size: 1.1vw;
          font-weight: 400;
          margin-left: 3px; }
          @media screen and (max-width: 1200px) {
            #contact #contact-container #contact-inside-container #contact-name-container #contact-name-ko span {
              font-size: 13.2px; } }
    #contact #contact-container #contact-inside-container #contact-information-container {
      padding: inherit;
      position: absolute;
      left: 0px;
      bottom: 0px; }
      #contact #contact-container #contact-inside-container #contact-information-container .contact-infromation-text {
        margin-top: 10px;
        font-size: 1.4vw; }
        @media screen and (max-width: 1200px) {
          #contact #contact-container #contact-inside-container #contact-information-container .contact-infromation-text {
            font-size: 16.8px; } }
        #contact #contact-container #contact-inside-container #contact-information-container .contact-infromation-text span {
          font-weight: 600;
          margin-right: 3vw; }
          @media screen and (max-width: 1200px) {
            #contact #contact-container #contact-inside-container #contact-information-container .contact-infromation-text span {
              margin-right: 36px; } }
        #contact #contact-container #contact-inside-container #contact-information-container .contact-infromation-text #white-space {
          margin-right: 4.2vw; }
          @media screen and (max-width: 1200px) {
            #contact #contact-container #contact-inside-container #contact-information-container .contact-infromation-text #white-space {
              margin-right: 50.4px; } }
    #contact #contact-container #contact-inside-container #contact-logo-container {
      padding: inherit;
      position: absolute;
      right: 0px;
      top: 0px;
      width: 12vw;
      height: 12vw;
      min-width: 144px;
      min-height: 144px; }
      #contact #contact-container #contact-inside-container #contact-logo-container img {
        width: 100%; }
    #contact #contact-container #contact-inside-container #contact-button-container {
      padding: inherit;
      position: absolute;
      right: 0px;
      bottom: 0px;
      text-align: right; }
      #contact #contact-container #contact-inside-container #contact-button-container a p {
        display: inline-block !important; }
      #contact #contact-container #contact-inside-container #contact-button-container a {
        text-decoration: none;
        color: black; }
        #contact #contact-container #contact-inside-container #contact-button-container a img {
          height: 1.3vw;
          margin-right: 0.5vw;
          vertical-align: sub; }
        #contact #contact-container #contact-inside-container #contact-button-container a p {
          cursor: pointer;
          margin-top: 10px;
          text-align: right;
          white-space: nowrap;
          font-size: 1.4vw;
          position: relative;
          font-weight: 600;
          z-index: 0; }
          @media screen and (max-width: 1200px) {
            #contact #contact-container #contact-inside-container #contact-button-container a p {
              font-size: 16.8px; } }
        #contact #contact-container #contact-inside-container #contact-button-container a p::after {
          content: '';
          position: absolute;
          bottom: 0px;
          left: 50%;
          transform: translate(-50%, 0%);
          width: 0px;
          height: 1px;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease; }
        #contact #contact-container #contact-inside-container #contact-button-container a .underline::after {
          content: '';
          position: absolute;
          bottom: -5px;
          left: 50%;
          transform: translate(-50%, 0%);
          width: 100%;
          height: 2px;
          background: black; }

#header-mobile {
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 3;
  background: white; }
  #header-mobile #header-mobile-container {
    height: 70px;
    position: relative; }
    #header-mobile #header-mobile-container #header-mobile-expand-icon {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translate(0%, -50%);
      width: 20px;
      height: 14px;
      vertical-align: top;
      display: inline-flex;
      flex-direction: column; }
      #header-mobile #header-mobile-container #header-mobile-expand-icon .header-mobile-expand-icon-line {
        position: absolute;
        display: inline-block;
        width: 100%;
        height: 2px;
        background: black;
        left: 0px;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease; }
      #header-mobile #header-mobile-container #header-mobile-expand-icon .header-mobile-expand-icon-line:nth-child(1) {
        top: 0px; }
      #header-mobile #header-mobile-container #header-mobile-expand-icon .header-mobile-expand-icon-line:nth-child(2) {
        top: 6px; }
      #header-mobile #header-mobile-container #header-mobile-expand-icon .header-mobile-expand-icon-line:nth-child(3) {
        top: 12px; }
    #header-mobile #header-mobile-container #header-mobile-expand-icon.close .header-mobile-expand-icon-line {
      top: 50%; }
    #header-mobile #header-mobile-container #header-mobile-expand-icon.close .header-mobile-expand-icon-line:nth-child(1) {
      transform: translate(0%, -50%) rotate(45deg); }
    #header-mobile #header-mobile-container #header-mobile-expand-icon.close .header-mobile-expand-icon-line:nth-child(2) {
      opacity: 0; }
    #header-mobile #header-mobile-container #header-mobile-expand-icon.close .header-mobile-expand-icon-line:nth-child(3) {
      transform: translate(0%, -50%) rotate(-45deg); }
    #header-mobile #header-mobile-container #header-mobile-logo {
      width: 30%;
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translate(0%, -50%);
      display: flex; }
      #header-mobile #header-mobile-container #header-mobile-logo img {
        width: 40%;
        height: 40%;
        object-fit: contain; }

#navigation {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 70px;
  left: 0;
  z-index: 2;
  opacity: 0;
  touch-action: none;
  pointer-events: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  #navigation #navigation-container {
    position: fixed;
    width: 100%; }
    #navigation #navigation-container ul {
      margin: 0px;
      padding: 0px;
      text-align: center; }
      #navigation #navigation-container ul li {
        padding: 23px 0px;
        background: white;
        list-style: none;
        border-bottom: 1px solid #e4e4e4; }
        #navigation #navigation-container ul li a {
          text-decoration: none;
          color: black; }
  #navigation #navigation-background {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%; }
  #navigation .navigation-language {
    width: 50%;
    display: inline-block; }
  #navigation .navigation-language:first-child {
    border-right: 1px solid #e4e4e4; }

#navigation.show {
  opacity: 1;
  touch-action: auto;
  pointer-events: all; }

#main-mobile {
  height: 100vh;
  position: relative; }
  #main-mobile #main-mobile-container {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%; }
  #main-mobile img {
    width: 30vw; }
  #main-mobile p {
    width: 100%;
    font-size: 20px;
    font-weight: 300; }

#do-mobile .do-mobile-content-container {
  overflow: hidden;
  position: relative;
  height: 450px; }
  #do-mobile .do-mobile-content-container .do-content-item img {
    width: 100%; }
  #do-mobile .do-mobile-content-container .do-content-item .text-container {
    padding: 20px;
    width: auto; }
    #do-mobile .do-mobile-content-container .do-content-item .text-container .text-title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 5px;
      margin-top: 0px; }
    #do-mobile .do-mobile-content-container .do-content-item .text-container .text-description {
      margin-top: 1px;
      font-size: 15px;
      width: 100%;
      word-break: break-all;
      margin-bottom: 0px; }
  #do-mobile .do-mobile-content-container #do-content-web-browser {
    width: 70%;
    position: absolute;
    top: 140px;
    left: 10px; }
  #do-mobile .do-mobile-content-container #do-content-web-photoshop {
    width: 60%;
    position: absolute;
    right: 10px;
    top: 200px; }
  #do-mobile .do-mobile-content-container #do-content-web-coding {
    width: 60%;
    position: absolute;
    right: 50px;
    top: 290px; }
  #do-mobile .do-mobile-content-container #do-content-responsive-ipad {
    width: 60%;
    position: absolute;
    top: 160px;
    left: 0px; }
  #do-mobile .do-mobile-content-container #do-content-responsive-iphone {
    width: 40%;
    position: absolute;
    top: 235px;
    left: -40px; }
  #do-mobile .do-mobile-content-container #do-content-responsive-macbook {
    width: 90%;
    position: absolute;
    top: 220px;
    left: 90px; }
  #do-mobile .do-mobile-content-container #do-content-outsourcing-union {
    width: 90%;
    position: absolute;
    top: 150px;
    left: 25px; }

#do-mobile .do-mobile-content-container:nth-child(2) {
  text-align: right;
  background: #f9f9f9; }

#history-mobile #history-container {
  width: auto;
  padding: 0px 4vw; }
  #history-mobile #history-container .history-year-container {
    width: 100%;
    padding: 30px 0px;
    position: relative; }
    #history-mobile #history-container .history-year-container .history-year {
      width: 15%;
      display: inline-block;
      float: left; }
      #history-mobile #history-container .history-year-container .history-year p {
        font-family: 'TitilliumWeb';
        font-weight: 600;
        color: #f42434;
        font-size: 20px;
        margin: 0px;
        letter-spacing: -0.095em;
        margin-top: -5px; }
    #history-mobile #history-container .history-year-container .history-month-content-container {
      width: 85%;
      display: inline-block; }
      #history-mobile #history-container .history-year-container .history-month-content-container p {
        font-size: 12px;
        margin: 0px; }
      #history-mobile #history-container .history-year-container .history-month-content-container .history-month-content-line {
        margin-bottom: 20px;
        display: flex; }
        #history-mobile #history-container .history-year-container .history-month-content-container .history-month-content-line .history-month-container {
          width: 4%;
          display: inline-block; }
          #history-mobile #history-container .history-year-container .history-month-content-container .history-month-content-line .history-month-container p {
            font-weight: 600; }
        #history-mobile #history-container .history-year-container .history-month-content-container .history-month-content-line .history-content-container {
          display: inline-block;
          padding-left: 10px; }
          #history-mobile #history-container .history-year-container .history-month-content-container .history-month-content-line .history-content-container p {
            font-weight: 400; }
      #history-mobile #history-container .history-year-container .history-month-content-container .history-month-content-line:last-child {
        margin-bottom: 0px; }
  #history-mobile #history-container .history-year-container::after {
    content: '';
    width: 100%;
    height: 1px;
    background: #dcdcdc;
    position: absolute;
    bottom: 0px;
    left: 0px; }

#member-mobile {
  padding: 20px 4vw; }
  #member-mobile #member-container {
    padding: 30px 4vw;
    display: inline-table;
    grid-template-columns: auto auto; }
    #member-mobile #member-container .member-people-container:nth-child(even) {
      margin-left: 0px; }
    #member-mobile #member-container .member-people-container {
      padding: 10px 0px;
      width: 40vw;
      border-radius: 20px;
      background: white;
      -moz-box-shadow: 2px 4px 10px 3px #ccc;
      -webkit-box-shadow: 2px 4px 10px 3px #ccc;
      box-shadow: 2px 4px 10px 3px #ccc;
      margin: 10px 3.9vw;
      text-align: center;
      display: inline-block; }
      #member-mobile #member-container .member-people-container .member-people-picture {
        width: 30vw;
        height: 30vw;
        margin: 10px auto;
        position: relative;
        border-radius: 50%;
        box-shadow: 0 13px 26px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.2);
        background-size: cover;
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        filter: grayscale(100%); }
      #member-mobile #member-container .member-people-container .co-founder-ko::after {
        content: '공동설립자';
        position: absolute;
        background: black;
        color: white;
        padding: 0.3vw 0.5vw;
        border-radius: 5px;
        right: -2vw;
        top: 0px;
        font-size: 8px; }
      #member-mobile #member-container .member-people-container .co-founder-en::after {
        content: 'Co-Founder';
        position: absolute;
        background: black;
        color: white;
        padding: 0.3vw 0.5vw;
        border-radius: 10px;
        right: -2vw;
        top: 0px;
        font-size: 12px; }
      #member-mobile #member-container .member-people-container p {
        margin: 0px; }
      #member-mobile #member-container .member-people-container .member-people-name {
        font-size: 13px;
        text-align: center;
        font-weight: 500;
        margin: 0px; }
      #member-mobile #member-container .member-people-container .member-people-position {
        font-size: 11px;
        text-align: center;
        color: #565656;
        z-index: 0;
        position: relative; }
      #member-mobile #member-container .member-people-container .member-people-position::before {
        content: '';
        width: 0%;
        height: 35%;
        position: absolute;
        left: -10%;
        top: 60%;
        background: #ffd969;
        z-index: -1;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease; }
      #member-mobile #member-container .member-people-container .member-people-position-effect::before {
        content: '';
        width: 120%;
        height: 35%;
        position: absolute;
        left: -10%;
        top: 60%;
        background: #ffd969;
        z-index: -1;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease; }
      #member-mobile #member-container .member-people-container .member-people-role {
        font-size: 10px;
        text-align: center;
        color: #565656;
        z-index: 0;
        position: relative; }
      #member-mobile #member-container .member-people-container .member-people-role::before {
        content: '';
        width: 0%;
        height: 35%;
        position: absolute;
        left: -10%;
        top: 60%;
        background: #ffd969;
        z-index: -1;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease; }
      #member-mobile #member-container .member-people-container .member-people-role-effect::before {
        content: '';
        width: 120%;
        height: 35%;
        position: absolute;
        left: -10%;
        top: 60%;
        background: #ffd969;
        z-index: -1;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease; }

#partner-mobile #partner-container {
  padding: 40px 4vw;
  display: inline-table;
  text-align: center; }

#partner-mobile .partner-card {
  width: 20vw;
  height: 15vw;
  border-radius: 10px;
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
  text-align: center;
  -moz-box-shadow: 2px 4px 10px 3px #ccc;
  -webkit-box-shadow: 2px 4px 10px 3px #ccc;
  box-shadow: 2px 4px 10px 3px #ccc;
  margin: 1vw 1vw; }
  #partner-mobile .partner-card img {
    width: 70%;
    height: 70%;
    object-fit: scale-down;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
  #partner-mobile .partner-card .partner-information-container {
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    #partner-mobile .partner-card .partner-information-container .partner-information {
      width: 100%;
      height: 100%;
      position: absolute;
      background: black;
      opacity: 0.8;
      border-radius: 20px; }
    #partner-mobile .partner-card .partner-information-container .partner-information-text {
      color: white;
      margin: 0px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 20px;
      pointer-events: none; }
  #partner-mobile .partner-card .partner-information-container-show {
    opacity: 1 !important; }

#notice-mobile #npContainer {
  display: grid;
  padding: 40px 4vw;
  grid-template-columns: 100%; }

#notice-mobile .npWrapper {
  width: 90%;
  margin: 0 auto; }
  #notice-mobile .npWrapper .np-title-container {
    position: relative;
    margin-bottom: 30px; }
    #notice-mobile .npWrapper .np-title-container .np-title {
      display: inline-block;
      font-size: 20px;
      font-weight: 600;
      margin: 0px; }
    #notice-mobile .npWrapper .np-title-container .np-viewall {
      position: absolute;
      right: 0px;
      bottom: 10px;
      font-size: 9px;
      background: black;
      color: white;
      border-radius: 20px;
      padding: 2px 10px; }
  #notice-mobile .npWrapper table {
    width: 100%;
    margin-top: 20px; }
    #notice-mobile .npWrapper table td {
      font-size: 15px;
      padding-bottom: 10px;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
      #notice-mobile .npWrapper table td a {
        text-decoration: none;
        color: black; }
    #notice-mobile .npWrapper table td:nth-child(2) {
      text-align: right;
      font-size: 15px;
      color: #565656; }

#notice-mobile .np-divider {
  width: 10%;
  height: 1px;
  background: black; }

#news-mobile #npContainer {
  display: grid;
  padding: 40px 4vw;
  grid-template-columns: 100%; }

#news-mobile .npWrapper {
  width: 90%;
  margin: 0 auto; }
  #news-mobile .npWrapper .np-title-container {
    position: relative;
    margin-bottom: 30px; }
    #news-mobile .npWrapper .np-title-container .np-title {
      display: inline-block;
      font-size: 25px;
      font-weight: 600;
      margin: 0px; }
    #news-mobile .npWrapper .np-title-container .np-viewall {
      position: absolute;
      right: 0px;
      bottom: 10px;
      font-size: 9px;
      background: black;
      color: white;
      border-radius: 20px;
      padding: 2px 10px; }
  #news-mobile .npWrapper table {
    width: 100%;
    margin-top: 20px; }
    #news-mobile .npWrapper table td {
      font-size: 15px;
      padding-bottom: 10px;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
      #news-mobile .npWrapper table td a {
        text-decoration: none;
        color: black; }
    #news-mobile .npWrapper table td:nth-child(2) {
      text-align: right;
      font-size: 15px;
      color: #565656; }

#news-mobile .np-divider {
  width: 10%;
  height: 1px;
  background: black; }

#contact-mobile #contact-container {
  width: 92vw;
  height: 58vw;
  margin: 0 4vw;
  background: white;
  -moz-box-shadow: 2px 4px 10px 3px #ccc;
  -webkit-box-shadow: 2px 4px 10px 3px #ccc;
  box-shadow: 2px 4px 10px 3px #ccc;
  position: relative;
  overflow: hidden; }
  #contact-mobile #contact-container #contact-inside-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5vw; }
    #contact-mobile #contact-container #contact-inside-container p {
      margin: 0px; }
    #contact-mobile #contact-container #contact-inside-container #contact-name-container {
      padding: inherit;
      position: absolute;
      left: 0px;
      top: 0px; }
      #contact-mobile #contact-container #contact-inside-container #contact-name-container #contact-name-en {
        font-family: 'Montserrat';
        font-size: 1.3rem;
        margin-bottom: 5px; }
      #contact-mobile #contact-container #contact-inside-container #contact-name-container #contact-name-ko {
        margin-top: -5px;
        font-size: 0.5rem;
        font-weight: 600;
        color: #939598; }
        #contact-mobile #contact-container #contact-inside-container #contact-name-container #contact-name-ko span {
          font-size: 0.3rem;
          font-weight: 400;
          margin-left: 3px; }
    #contact-mobile #contact-container #contact-inside-container #contact-information-container {
      padding: inherit;
      position: absolute;
      left: 0px;
      bottom: 0px; }
      #contact-mobile #contact-container #contact-inside-container #contact-information-container .contact-infromation-text {
        margin-top: 0.2rem;
        font-size: 0.5rem; }
        #contact-mobile #contact-container #contact-inside-container #contact-information-container .contact-infromation-text span {
          font-weight: 600;
          margin-right: 3vw; }
        #contact-mobile #contact-container #contact-inside-container #contact-information-container .contact-infromation-text #white-space {
          margin-right: 4.2vw; }
    #contact-mobile #contact-container #contact-inside-container #contact-logo-container {
      padding: inherit;
      position: absolute;
      right: 0px;
      top: 0px;
      width: 17vw;
      height: 17vw; }
      #contact-mobile #contact-container #contact-inside-container #contact-logo-container img {
        width: 100%; }
    #contact-mobile #contact-container #contact-inside-container #contact-button-container {
      padding: inherit;
      position: absolute;
      right: 0px;
      bottom: 0px;
      text-align: right; }
      #contact-mobile #contact-container #contact-inside-container #contact-button-container a p {
        display: inline-block !important; }
      #contact-mobile #contact-container #contact-inside-container #contact-button-container a {
        text-decoration: none;
        color: black; }
        #contact-mobile #contact-container #contact-inside-container #contact-button-container a img {
          height: 3.0vw;
          margin-right: 0.5vw;
          vertical-align: sub; }
        #contact-mobile #contact-container #contact-inside-container #contact-button-container a p {
          cursor: pointer;
          margin-top: 5px;
          text-align: right;
          white-space: nowrap;
          font-size: 0.5rem;
          position: relative;
          font-weight: 600;
          z-index: 0; }
        #contact-mobile #contact-container #contact-inside-container #contact-button-container a p::after {
          content: '';
          position: absolute;
          bottom: 0px;
          left: 50%;
          transform: translate(-50%, 0%);
          width: 0px;
          height: 1px;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease; }
        #contact-mobile #contact-container #contact-inside-container #contact-button-container a .underline::after {
          content: '';
          position: absolute;
          bottom: -5px;
          left: 50%;
          transform: translate(-50%, 0%);
          width: 100%;
          height: 2px;
          background: black; }

#footer {
  padding: 50px !important;
  text-align: center; }
  #footer #footer-container p {
    margin: 1px 0px;
    font-size: 1vw; }
    @media only screen and (max-width: 600px) {
      #footer #footer-container p {
        font-size: 2vw; } }
    #footer #footer-container p span {
      padding: 0px 1vw;
      position: relative; }
      @media only screen and (max-width: 600px) {
        #footer #footer-container p span {
          padding: 0px 2vw; } }
    #footer #footer-container p span::after {
      content: '';
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translate(0%, -50%);
      width: 1px;
      height: 50%;
      background: black; }
    #footer #footer-container p span:last-child::after {
      content: none; }
  #footer #footer-container a {
    color: black;
    text-decoration: none !important; }
  #footer #footer-container a:hover {
    color: black;
    text-decoration: none !important; }

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 300;
  src: url(../font/NotoSansCJKkr-Light.woff); }

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  src: url(../font/NotoSansCJKkr-Regular.woff); }

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  src: url(../font/NotoSansCJKkr-Medium.woff); }

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  src: url(../font/NotoSansCJKkr-Bold.woff); }

@font-face {
  font-family: "TitilliumWeb";
  font-style: normal;
  font-weight: 400;
  src: url(../font/TitilliumWeb-Regular.woff); }

@font-face {
  font-family: "TitilliumWeb";
  font-style: normal;
  font-weight: 500;
  src: url(../font/TitilliumWeb-SemiBold.woff); }

body {
  font-family: "Noto Sans KR", sans-serif;
  letter-spacing: -0.025em;
  margin: 0 auto; }

.pc {
  min-width: 1200px;
  overflow-x: hidden; }
  .pc #contents > div:nth-child(odd) {
    background-color: #f9f9f9; }
  .pc #contents > div:first-child {
    padding: 0px; }
  .pc #contents > div {
    padding: 100px 0px; }
  .pc .card-container {
    display: flex;
    justify-content: center;
    padding: 40px 0px;
    flex-wrap: wrap;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .pc .card-center {
    margin: 0px 5vw; }
  .pc .card {
    width: 24.21875vw;
    height: 15.989583vw;
    min-width: 290px;
    min-height: 195.78px;
    max-width: 465px;
    max-height: 307px;
    margin-bottom: 2.5vw;
    border-radius: 20px;
    display: inline-block;
    -moz-box-shadow: 2px 9px 10px 3px #ccc;
    -webkit-box-shadow: 2px 9px 10px 3px #ccc;
    box-shadow: 2px 9px 10px 3px #ccc;
    overflow: hidden;
    position: relative;
    pointer-events: none; }
    @media screen and (max-width: 1200px) {
      .pc .card {
        margin-right: 48px; } }
  .pc .card-gradient {
    position: absolute;
    width: 101%;
    height: 100%;
    left: 0px;
    top: 0px; }
  .pc .card-content {
    width: 25vw;
    height: 100%; }
    .pc .card-content p {
      margin: 0px;
      text-align: center; }
    .pc .card-content .card-title {
      width: 100%;
      position: absolute;
      font-size: 1.66vw;
      font-weight: 700;
      left: 50%;
      top: 10%;
      transform: translate(-50%, -50%); }
      @media screen and (max-width: 1200px) {
        .pc .card-content .card-title {
          font-size: 20px; } }
      @media screen and (min-width: 1920px) {
        .pc .card-content .card-title {
          font-size: 31.872px; } }
    .pc .card-content .card-description {
      width: 100%;
      font-size: 1vw;
      font-weight: 400;
      position: absolute;
      left: 50%;
      top: 22%;
      transform: translate(-50%, -50%);
      opacity: 0.8; }
      @media screen and (max-width: 1200px) {
        .pc .card-content .card-description {
          font-size: 12px; } }
      @media screen and (min-width: 1920px) {
        .pc .card-content .card-description {
          font-size: 19.2px; } }
    .pc .card-content .card-image {
      width: 100%;
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translate(-50%, 0px); }
    .pc .card-content .card-link {
      position: absolute;
      width: 40px;
      right: 10px;
      bottom: 10px;
      cursor: pointer;
      pointer-events: all; }
  .pc .sub-header {
    text-align: center; }
    .pc .sub-header p {
      margin: 0px; }
    .pc .sub-header .divider {
      height: 1px;
      background: black;
      width: 50px;
      margin: 30px auto; }
    .pc .sub-header .title {
      font-size: 40px;
      font-weight: 700; }
    .pc .sub-header .description {
      font-size: 18px;
      margin-bottom: 20px !important; }
      .pc .sub-header .description span {
        position: relative;
        z-index: 1; }
      .pc .sub-header .description span:before {
        content: "";
        position: absolute;
        top: 10px;
        left: 0px;
        width: 100%;
        height: 60%;
        background-color: #ffe167;
        z-index: -1; }
  .pc #return-to-top {
    position: fixed;
    right: 40px;
    bottom: 80px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #f42434;
    opacity: 0;
    cursor: pointer;
    -webkit-box-shadow: 0px 6px 24px -2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 6px 24px -2px rgba(0, 0, 0, 0.3);
    z-index: 100;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease; }
    .pc #return-to-top img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70%; }

.mobile #contents > div:nth-child(odd) {
  background-color: #f9f9f9; }

.mobile #contents > div {
  padding: 20px 0px; }

.mobile .card-container {
  display: inline-block;
  padding: 20px 7.5vw;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.mobile .card:nth-child(odd) {
  margin-right: 5vw; }

.mobile .card {
  width: 40vw;
  height: 26.5086016vw;
  border-radius: 10px;
  margin: 2vw 0px;
  display: inline-block;
  -moz-box-shadow: 2px 9px 10px 3px #ccc;
  -webkit-box-shadow: 2px 9px 10px 3px #ccc;
  box-shadow: 2px 9px 10px 3px #ccc;
  overflow: hidden;
  position: relative;
  pointer-events: none; }

.mobile .card-gradient {
  position: absolute;
  width: 101%;
  height: 100%;
  left: 0px;
  top: 0px; }

.mobile .card-content {
  width: 101%;
  height: 100%; }
  .mobile .card-content p {
    margin: 0px;
    text-align: center; }
  .mobile .card-content .card-title {
    width: 100%;
    position: absolute;
    font-size: 10px;
    font-weight: 700;
    left: 50%;
    top: 15%;
    transform: translate(-50%, -50%); }
  .mobile .card-content .card-image {
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0px); }
  .mobile .card-content .card-link {
    position: absolute;
    width: 10%;
    right: 5px;
    bottom: 5px;
    cursor: pointer;
    pointer-events: all; }

.mobile .sub-header {
  text-align: center; }
  .mobile .sub-header p {
    margin: 0px; }
  .mobile .sub-header .divider {
    height: 1px;
    background: black;
    width: 50px;
    margin: 15px auto; }
  .mobile .sub-header .title {
    font-size: 25px;
    font-weight: 700; }
  .mobile .sub-header .description {
    font-size: 15px;
    margin-bottom: 20px !important;
    padding: 0px 4vw;
    word-break: keep-all; }
    .mobile .sub-header .description span {
      position: relative;
      z-index: 1; }
    .mobile .sub-header .description span:before {
      content: "";
      position: absolute;
      top: 10px;
      left: 0px;
      width: 100%;
      height: 60%;
      background-color: #ffe167;
      z-index: -1; }

.mobile #return-to-top {
  position: fixed;
  right: 20px;
  bottom: 30px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f42434;
  opacity: 0;
  cursor: pointer;
  -webkit-box-shadow: 0px 6px 24px -2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 6px 24px -2px rgba(0, 0, 0, 0.3);
  z-index: 1; }
  .mobile #return-to-top img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%; }

.text-container {
  width: 280px;
  padding-left: 20px; }

.shadow {
  -moz-box-shadow: 2px 4px 10px 3px #ccc;
  -webkit-box-shadow: 2px 4px 10px 3px #ccc;
  box-shadow: 2px 4px 10px 3px #ccc; }

.text-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px; }

.text-description {
  width: 230px;
  font-size: 18px;
  word-break: keep-all; }

.en-font {
  font-family: "TitilliumWeb", "Noto Sans KR", sans-serif; }

.top-show {
  opacity: 1 !important;
  bottom: 40px !important; }

.top-active {
  width: 70px !important;
  height: 70px !important;
  right: 35px !important;
  bottom: 35px !important; }
